import React, { ReactElement, useContext } from 'react';
import classes from 'classnames';

import s from '../style.module.css';

import { ThemeContext } from '~/contexts/app';

import { NotificationConfig, NotificationTextContent } from '@models/application/notifications';
import BasicContentNotification from '@components/layout/notification/variations/BasicContentNotification';

interface Props {
  emoji: ReactElement | null;
  text: string;
  closable: boolean;
  onHideClick: () => void;
}

const BasicNotification = ({ emoji, text, closable, onHideClick }: Props) => {
  const showEmoji = useContext(ThemeContext).isEmojiEnabled;

  return (
    <BasicContentNotification closable={closable} onHideClick={onHideClick}>
      <>
        {emoji && showEmoji && (
          <img
            className={classes(
              s.notification__emoji,
              s.notification__emoji_preface,
            )}
            src={emoji}
            alt=""
          />
        )}
        {text}
      </>
    </BasicContentNotification>
  );
};

export default BasicNotification;

export const buildBasicNotification = (
  config: NotificationConfig,
  content: NotificationTextContent,
  icon: ReactElement,
  onHide: () => void,
) => (
  <BasicNotification
    closable={config.closable}
    emoji={icon}
    text={content.text}
    onHideClick={onHide}
  />
);
