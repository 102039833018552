import { ACHRelationship } from '@models/transfers';

export const DEFAULT_ACH_RELATIONSHIP: ACHRelationship = {
  account_owner_name: '',
  account_type: 'CHECKING',
  account_number: '',
  routing_number: '',
  nickname: '',
  balance: 0,
  display_account_type: 'Checking',
};
