import { forwardRef, lazy, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import classes from 'classnames';
import { NavLink, useRouteMatch } from 'react-router-dom';

import logoutGrayIcon from '@icons/controls/gray/log-out.svg';

import s from './style.module.css';
import { header as hrClasses } from '@constants/customization';

import BurgerBtn from '@components/common/controls/btns/burger';
import HorizontalMenu from '@components/layout/horizontal-menu';
import MobileMenu from '@components/layout/mobile-menu/MobileMenu';

import * as authActions from '@store/authentication/actions';
import { MAIN } from '~/routes/names';
import { ThemeContext } from '~/contexts/app';
import * as S from './TopHeader.styled';

const Comp = lazy(() => import('@components/icons/Questions'));

const LogoutBtn = ({ className }) => {
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(authActions.logout());

  return (
    <button
      className={classes(s.logoutBtn, className)}
      onClick={handleLogout}
    >
      <img src={logoutGrayIcon} alt="logout" />
    </button>
  );
};

interface Props {
  type?: 'general' | 'vendor',
  className: string;
}

const MenuItem = ({ link }) => {
  const Icon = link.component;
  const linkMatch = useRouteMatch(link.path);

  return (
    <S.MenuItem
      $isActive={linkMatch?.isExact || false}
      link={link.path}
      key={link.path + link.title}
    >
      <S.Icon>
        <Icon />
      </S.Icon>
    </S.MenuItem>
  );
};

const TheHeader = ({ className, type = 'general' }: Props, ref) => {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const forVendor = type === 'vendor';

  const logoURL = useContext(ThemeContext).logo;
  const displayName = useContext(ThemeContext).displayName;

  return (
    <header
      className={classes(
        s.header,
        className,
        !forVendor && s.header_general,
      )}
      ref={ref}
    >
      <div className="container">
        <div className={s.header__content}>
          <NavLink
            className={classes(s.header__content__link, hrClasses.logo)}
            to={MAIN}
          >
            <img
              src={logoURL}
              className={s.header__content__icon}
              alt={displayName}
            />
          </NavLink>

          <HorizontalMenu className={s.header__content__horizontal} />

          <S.ControlsContainer>
            <MenuItem link={{ component: Comp, path: '/faq' }}></MenuItem>

            <LogoutBtn
              className={classes(s.header__content__logoutBtn, hrClasses.logout)}
            />

            <BurgerBtn
              className={classes(
                s.header__content__burger,
                isMenuOpened && s.header__content__burger_opened,
              )}
              opened={isMenuOpened}
              onClick={() => setMenuOpened(!isMenuOpened)}
            />

            <MobileMenu
              className={s.header__content__mobile}
              useWhiteLogo={!forVendor}
              visible={isMenuOpened}
              onClose={() => setMenuOpened(false)}
            />
          </S.ControlsContainer>
        </div>
      </div>
    </header>
  );
};

export default forwardRef(TheHeader);
