import styled from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';

export const Container = styled.div`
    margin: auto;
`;

export const FeesLink = styled(Button).attrs({
  link: '/faq',
  type: 'link'
})`
  &.ant-btn {
    font-size: inherit;
    
    &, &:hover, &:focus, &:active {
      color: inherit;
    }
  }
`;

export const DisclosuresLink = styled.a`
  &, &:hover, &:focus, &:active {
    color: inherit;
  }

  &:focus-visible {
    outline-color: var(--eko-button-outline-color);
  }
`;
