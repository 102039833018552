import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getIsAccountClosure, selectProfile } from '@store/profile/selectors';
import { getMenuItems } from '@services/application';

import * as S from './HorizontalMenu.styled';

const MenuItem = ({ link }) => {
  const Icon = link.component;
  const linkMatch = useRouteMatch(link.path);

  return (
    <S.MenuItem
      $isActive={linkMatch?.isExact || false}
      link={link.path}
      key={link.path + link.title}
    >
      <S.Icon>
        <Icon />
      </S.Icon>

      <S.Title>{link.title}</S.Title>
    </S.MenuItem>
  );
};

const HorizontalMenu = ({ className }) => {
  const profile = useSelector(selectProfile);
  const closure = getIsAccountClosure();

  const links = getMenuItems(profile.has_alpaca_account, closure);

  return (
    <S.Container className={className}>
      {links.map(link => <MenuItem link={link} />)}
    </S.Container>
  );
};

export default HorizontalMenu;
