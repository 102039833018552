import React from 'react';
import classes from 'classnames';

export default function Loader ({
  centralize = false,
  className = '',
  customStyle = {},
}) {
  const loader = (
    <div
      className={classes(className, 'spinner-border')}
      style={{
        borderWidth: '2px',
        ...customStyle,
      }}
      role="status"
    />
  );

  if (centralize) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        {loader}
      </div>
    );
  }

  return loader;
}
