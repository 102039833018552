import classes from 'classnames';
import { ReactElement } from 'react';

import s from './style.module.css';

interface Props {
  type?: 'basic' | 'large',
  className?: string;
  children: ReactElement | ReactElement[];
  [key: string]: any;
}

const ACHCard = ({
  children,
  type = 'basic',
  className = '',
  ...rest
}: Props) => (
  <div className={classes(s.achCard, s[`achCard_${type}`], className)} {...rest}>
    {children}
  </div>
);

export default ACHCard;
