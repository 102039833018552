import React from "react";

import CaretIcon from '~/assets/images/caret.svg';
import styles from './SelectInput.module.css';

export default function SelectDropdown({
  value,
  onChange,
  options,
  onBlur,
  style,
  onKeyUp,
  refvalue,
  onKeyDown,
  onFocus,
}) {
  return (
    <div style={{ position: "relative", ...style }}>
      <select
        ref={refvalue}
        onBlur={onBlur}
        tabIndex="-1"
        value={value}
        onChange={onChange}
        className={styles.selectDropdown}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      >
        <option value="">Choose</option>

        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      <img
        src={CaretIcon}
        alt=""
        className={styles.dropdownCaret}
      />
    </div>
  );
}
