import styled, { css } from 'styled-components';

import { media } from '~/ui/utils/media';
import { gaps } from '~/ui/kit/constants/sizes';
import { accentFont, baseFont, fontSize, TABLE_FONT_SIZE } from '~/ui/kit/constants/fonts';

import FundAccountBtn from '../FundAccountBtn/FundAccountBtn';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: ${gaps.xl}px;

  ${media.md`
    flex-direction: row-reverse;
    align-items: center;
  `}
`;

export const Title = styled.div.attrs<{$clickable?: boolean}>({ $fs: 'xxl' })<{$clickable?: boolean}>`
  display: flex;
  align-items: center;
  gap: ${gaps.m}px;
  ${accentFont};
  font-weight: bold;
  margin-bottom: ${gaps.xxs}px;
  ${fontSize};
  font-size: ${TABLE_FONT_SIZE.xxl}px;
  
  ${({ $clickable }) => $clickable && css`
    cursor: pointer;
  `}
`;

export const FundButton = styled(FundAccountBtn)`
  width: 100%;
  
  ${media.md`
    width: auto;
  `}
`;

export const ContentContainer = styled.div`
  width: 100%;
  flex: 1;
`;

export const Subtitle = styled.div.attrs({ $fs: 's' })`
  ${baseFont};
  ${fontSize};
  font-size: ${TABLE_FONT_SIZE.s}px;
  margin-top: ${gaps.xs}px;
`;
