import React from 'react';
import styles from './Checkbox.module.css';

export default function Checkbox ({ title, customStyle, onChange, value }) {
  return (
    <label tabIndex="-1" className={styles.container} style={customStyle}>
      {title}
      <input
        onChange={onChange}
        tabIndex="-1"
        type="checkbox"
        checked={value}
      />
      <span className={styles.checkmark}></span>
    </label>
  );
}

Checkbox.defaultProps = {
  customStyle: {}
};
