import React, { useContext } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classes from 'classnames';

import styles from './LeftMenu.module.css';

import * as customization from '~/constants/customization';
import { ThemeContext } from '~/contexts/app';
import LogoutIcon from '@components/icons/Logout';

import setCompanyDetails from '~/actions/setCompanyDetails';
import { getIsAccountClosure, selectProfile } from '@store/profile/selectors';

import { getMenuItems, FAQMenuItem } from '@services/application';
import { capitalizeFirstLetter } from '@utils/strings';

function LeftMenu ({ handleLogout, hasAlpacaAccount }) {
  const dispatch = useDispatch();

  const profileData = useSelector(selectProfile);
  const companyDetailsVisible = useSelector(
    state => state.application.companyDetailsVisible,
  );

  const closure = getIsAccountClosure();
  const logoURL = useContext(ThemeContext).logo;
  const displayName = useContext(ThemeContext).displayName;
  const links = getMenuItems(hasAlpacaAccount, closure);

  const isClosePopupRequired = link => companyDetailsVisible
    && (link.path === '/' || link.path === '/impact');

  const closeCompanyPopup = () => {
    dispatch(setCompanyDetails(false));
  };

  return (
    <div className={classes(styles.root, customization.leftMenu.root)}>
      <div className={styles.wrapper}>
        <div className={styles.menuTop}>
          <Link to="/">
            <img
              className={classes(styles.logoImage, customization.leftMenu.logoImage)}
              src={logoURL}
              alt={displayName}
            />
          </Link>
          {profileData.preferred_name && (
            <div className={classes(styles.name, customization.leftMenu.name)}>
              Welcome, {capitalizeFirstLetter(profileData.preferred_name)}!
            </div>
          )}
        </div>

        <div className={styles.menu}>
          <div>
            {links.map((link, i) => {
              const closePopup = isClosePopupRequired(link);
              const Icon = link.component;

              return (
                <NavLink
                  className={classes(styles.menuItem, customization.leftMenu.menuItem)}
                  activeClassName={styles.active}
                  exact={true}
                  to={link.path}
                  key={i}
                  onClick={() => {
                    if (closePopup) {
                      closeCompanyPopup();
                    }
                  }}
                >
                  <div className={styles.icon}>
                    <Icon />
                  </div>
                  {link.title}
                </NavLink>
              );
            })}
            <NavLink
              className={classes(styles.menuItem, customization.leftMenu.menuItem)}
              activeClassName={styles.active}
              exact={true}
              to={FAQMenuItem.path}
              onClick={closeCompanyPopup}
            >
              <div className={styles.icon}>
                <FAQMenuItem.component />
              </div>
              {FAQMenuItem.title}
            </NavLink>
          </div>
        </div>

        <button className={classes(styles.menuItem, customization.leftMenu.menuItem)} onClick={handleLogout}>
          <div className={styles.icon}>
            <LogoutIcon />
          </div>
          Logout
        </button>
      </div>
    </div>
  );
}

export default withRouter(LeftMenu);
