import { lazy } from 'react';

export const fundedLinks = [
  {
    path: '/',
    title: 'Performance',
    component: lazy(() => import('@components/icons/Performance')),
  },
  {
    path: '/portfolio',
    title: 'Portfolio',
    component: lazy(() => import('@components/icons/Portfolio')),
  },
  {
    path: '/fund_withdraw',
    title: 'Fund / Withdraw',
    component: lazy(() => import('@components/icons/FundWithdraw')),
  },
  {
    path: '/account',
    title: 'Account',
    component: lazy(() => import('@components/icons/Settings')),
  },
];

export const unfundedLinks = [
  {
    path: '/',
    title: 'Performance',
    component: lazy(() => import('@components/icons/Performance')),
  },
  {
    path: '/portfolio',
    title: 'Portfolio',
    component: lazy(() => import('@components/icons/Portfolio')),
  },
  {
    path: '/fund_withdraw',
    title: 'Fund / Withdraw',
    component: lazy(() => import('@components/icons/FundWithdraw')),
  },
  {
    path: '/settings',
    title: 'Settings',
    component: lazy(() => import('@components/icons/Settings')),
  },
];

export const closureLinks = [
  {
    path: '/account',
    title: 'Account',
    component: lazy(() => import('@components/icons/Settings')),
  },
];

export const FAQMenuItem = {
  path: '/faq',
  title: 'Knowledge center',
  component: lazy(() => import('@components/icons/Questions')),
};
