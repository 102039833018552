import { useSelector } from 'react-redux';

import { selectIsApplicationError } from '@store/application/selectors';
import ErrorSection from '@components/error';

export const ErrorWrapper = ({ children }) => {
  const hasError = useSelector(selectIsApplicationError);

  if (hasError) {
    return (
      <ErrorSection />
    );
  }

  return children;
};
