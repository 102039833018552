import styled, { css } from 'styled-components';
import { Button } from '~/ui/kit/atoms/Button';
import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

export const ControlsContainer = styled.div`
  display: flex;
  gap: ${gaps.m}px;
`;

type MenuItemProps = {
  $isActive: boolean
}

export const MenuItem = styled(Button).attrs<MenuItemProps>({
  type: 'link',
  noDecoration: true
})<MenuItemProps>`
  &.ant-btn {
    display: none;
    
    @media (min-width: 769px) {
      display: flex;
    }

    column-gap: 8px;
    padding: 8px 32px;
    text-decoration: none;
    border-radius: 4px;
    align-items: center;

    @media (max-width: 1200px) {
      padding-inline: 12px;
    }

    ${({ $isActive }) => $isActive && css`
      background-color: var(--eko-header-menu-item-active-bg);

      .menuItem__title {
        font-weight: 700;
      }
    `}
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;

  svg * {
    fill: var(--eko-header-fg-color);
  }
`;
