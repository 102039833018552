import CashIcon from '@assets/images/cash.png';
import RocketIcon from '@assets/images/rocketIcon.png';

import { buildBasicNotification } from '@components/layout/notification/variations/BasicNotification';
import VerifyEmailNotification from '@components/layout/notification/components/VerifyEmailNotification';

import { Notification, NotificationType } from '@models/application/notifications';
import { DepositSuccessfulPayload } from '@models/application/notifications/deposit';
import { WithdrawalSuccessfulPayload } from '@models/application/notifications/withdrawal';
import { AccountClosurePayload } from '@models/application/notifications/closure';
import { VerifyEmailPayload } from '@models/application/notifications/email';
import { InfoNotificationPayload } from '@models/application/notifications/info';

import { hideNotification } from '@utils/notifications';

const notificationMap = [
  {
    type: NotificationType.SuccessfulDeposit,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as DepositSuccessfulPayload;
      return buildBasicNotification(
        config,
        content,
        RocketIcon,
        () => hideNotification(notification),
      );
    },
  },

  {
    type: NotificationType.SuccessfulWithdrawal,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as WithdrawalSuccessfulPayload;
      return buildBasicNotification(
        config,
        content,
        CashIcon,
        () => hideNotification(notification),
      );
    },
  },

  {
    type: NotificationType.AccountClosure,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as AccountClosurePayload;
      return buildBasicNotification(
        config,
        content,
        CashIcon,
        () => hideNotification(notification),
      );
    },
  },

  {
    type: NotificationType.VerifyEmail,
    component: (notification: Notification) => {
      const { config } = notification.payload as VerifyEmailPayload;
      return <VerifyEmailNotification
        closable={config.closable}
        onHideClick={() => hideNotification(notification)}
      />;
    },
  },
  {
    type: NotificationType.Info,
    component: (notification: Notification) => {
      const { content, config } = notification.payload as InfoNotificationPayload;
      return buildBasicNotification(
        config,
        content,
        null,
        () => hideNotification(notification),
      );
    },
  },
];

export default notificationMap;
