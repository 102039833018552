import styled, { css } from 'styled-components';

import { Button } from '~/ui/kit/atoms/Button';

export const Container = styled.nav`
  display: flex;

  @media (max-width: 1200px) {
    column-gap: 20px;
  }
`;

type MenuItemProps = {
  $isActive: boolean
}

export const MenuItem = styled(Button).attrs<MenuItemProps>({
  type: 'link',
  noDecoration: true
})<MenuItemProps>`
  &.ant-btn {
    display: flex;
    column-gap: 8px;
    padding: 8px 32px;
    text-decoration: none;
    border-radius: 4px;
    align-items: center;
    color: var(--eko-header-fg-color);

    @media (max-width: 1200px) {
      padding-inline: 12px;
    }

    ${({ $isActive }) => $isActive && css`
    background-color: var(--eko-header-menu-item-active-bg);

    .menuItem__title {
      font-weight: 700;
    }
  `}
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
`;
