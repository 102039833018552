import { useContext, useEffect, useRef } from 'react';
import classes from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import * as customization from '@constants/customization';
import { leftMenu as lmClasses } from '@constants/customization';

import s from './style.module.css';
import { ThemeContext } from '~/contexts/app';
import { getIsAccountClosure, selectProfile } from '@store/profile/selectors';
import * as authActions from '@store/authentication/actions';
import { getMenuItems, FAQMenuItem } from '@services/application';
import Logout from '@components/icons/Logout';

import * as S from './MobileMenu.styled';
import { LogoutButton } from './MobileMenu.styled';

const MenuItem = ({ link, index, onClose }) => {
  const Component = link.component;

  return (
    <S.MenuItem
      className={classes(s.menuItem)}
      link={link.path}
      key={index}
      onClick={onClose}
    >
      <div className={s.icon}>
        <Component />
      </div>

      {link.title}
    </S.MenuItem>
  );
};

interface Props {
  className: string;
  useWhiteLogo: boolean;
  visible: boolean;
  onClose: () => void;
}

const MobileMenu = ({ className, useWhiteLogo, visible, onClose }: Props) => {
  const dispatch = useDispatch();
  const mobileRoot = useRef(null);

  const profile = useSelector(selectProfile);
  const closure = getIsAccountClosure();

  const logoURL = useContext(ThemeContext).logo;
  const menuLinks = getMenuItems(profile.has_alpaca_account, closure);

  const handleLogout = () => dispatch(authActions.logout());

  const closeMenu = () => {
    clearAllBodyScrollLocks();
    onClose();
  };

  useEffect(() => {
    if (visible) {
      disableBodyScroll(mobileRoot.current);
      return closeMenu;
    }
  }, [, visible]);

  return (
    <div
      className={classes(
        s.mobileMenu,
        className,
        visible && s.open,
      )}
      ref={mobileRoot}
    >
      <div className={s.menu}>
        <div className={s.menu__container}>
          {menuLinks.map((link, i) => {
            return (
              <MenuItem
                link={link}
                index={i}
                onClose={() => {
                  closeMenu();
                }}
              />
            );
          })}

          <MenuItem index={menuLinks.length} link={FAQMenuItem} onClose={closeMenu} />
        </div>

        <div className={classes(s.divider, lmClasses.divider)}></div>

        <S.LogoutButton
          className={classes(
            s.menuItem,
            s.menuItem_logout,
            customization.leftMenu.menuItem,
          )}
          onClick={handleLogout}
        >
          <div className={s.icon}>
            <Logout />
          </div>

          Logout
        </S.LogoutButton>
      </div>
    </div>
  );
};

export default MobileMenu;
