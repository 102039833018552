import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectProfile } from '@store/profile/selectors';
import LeftArrowIcon from 'assets/images/leftArrow';

import * as S from './TopHeader.styled';

type Props = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  withBack?: boolean;
  showFundBtn?: boolean;
  className?: string;
}

export const TopHeader: React.FC<Props> = ({
  title,
  subTitle = '',
  withBack = false,
  showFundBtn = false,
  className = undefined
}) => {
  const profile = useSelector(selectProfile);
  const history = useHistory();

  const showBack = withBack && history.length;

  const goBack = useMemo(() => showBack ? () => {
    history.goBack();
  } : undefined, [showBack]);

  return (
    <S.Container className={className}>
      {!(profile.closure || profile.rejected) && showFundBtn && <S.FundButton />}
      <S.ContentContainer>
        <S.Title onClick={goBack} $clickable={showBack}>
          {!!goBack && <img src={LeftArrowIcon} alt="" />}
          {title}
        </S.Title>
        {subTitle && <S.Subtitle>{subTitle}</S.Subtitle>}
      </S.ContentContainer>
    </S.Container>
  );
};

export default TopHeader;
