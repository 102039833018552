import { ReactElement } from 'react';

import ACHCard from '../ach_card';
import Loader from '@components/Loader/Loader';

const ACHLoading = ({ children, className }: { children?: ReactElement | ReactElement[]; className?: string }) => (
  <ACHCard className={className}>
    {children || (
      <Loader
        customStyle={{ height: '1rem', width: '1rem' }}
        centralize
      />
    )
    }
  </ACHCard>
);

export default ACHLoading;
